<template>
	<div>
		<div style="margin-top: 10%;padding: 2%">
			<el-form :model="form" :label-position="labelPosition">
				<el-form-item label="借款人(企业)">
					<el-input v-model="form.borrower" />
				</el-form-item>
				<el-form-item label="借款金额">
					<el-input v-model="form.loanAmount" />
				</el-form-item>
				<el-form-item label="利率">
					<el-input v-model="form.interestRate" />
				</el-form-item>
				<el-form-item label="借款合同(借据)编号">
					<el-input v-model="form.number" />
				</el-form-item>
				<el-form-item label="借款项目">
					<el-input v-model="form.loanProgram" />
				</el-form-item>
				<el-form-item label="摘要">
					<el-input v-model="form.abstractMessage" />
				</el-form-item>
				<el-form-item label="借款种类">
					<el-input v-model="form.loanType" />
				</el-form-item>
				<el-form-item label="抵押品(担保品)内容">
					<el-input v-model="form.collateral" />
				</el-form-item>
				<el-form-item label="保证人">
					<el-input v-model="form.guarantor" />
				</el-form-item>
				<el-form-item label="借款日期">
					<el-date-picker
						v-model="form.loanDate"
						type="date"
						placeholder="选择借款日期"
						format="YYYY-MM-DD"
						value-format="YYYY-MM-DD"
					/>
				</el-form-item>
				<el-form-item label="约定偿还日期">
					<el-date-picker
						v-model="form.reimburseDate"
						type="date"
						placeholder="选择约定偿还日期"
						format="YYYY-MM-DD"
						value-format="YYYY-MM-DD"
						@blur="getDays"
					/>
				</el-form-item>
				<el-form-item label="截止还款日利息额">
					<el-input v-model="form.interest" />
				</el-form-item>
				<el-form-item label="截止还款日应还总额">
					<el-input v-model="form.allMoney" />
				</el-form-item>
				<el-form-item label="我方经办人">
					<el-input v-model="form.handledBy" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="saveLoanMain">提交</el-button>
					<!--<el-button>Cancel</el-button>-->
				</el-form-item>
			</el-form>
		</div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowTimes} from "@/utils/time";

export default {
	name: "Loan_main",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			form: {
				id: '',
				borrower: '',
				loanAmount: '',
				interestRate: '',
				number: '',
				loanProgram: '',
				abstractMessage: '',
				loanType: '',
				collateral: '',
				guarantor: '',
				loanDate: '',
				reimburseDate: '',
				interest: '',
				allMoney: '',
				handledBy: '',
				addDate: ''
			},
			labelPosition: 'top',
			user_id: ''
		}
	},
	created() {
		if (!this.$route.params.key){
			ElMessage.error('非法访问!')
			this.$router.push("/loan_index")
		}else {
			this.form.addDate = setNowTimes(new Date())
			this.user_id = this.$route.params.user_id
		}
	},
	methods: {
		getDays() {
			// 获取两个日期对象的时间戳
			let time1 = new Date(this.form.loanDate).getTime();
			let time2 = new Date(this.form.reimburseDate).getTime();

			// 计算相差的毫秒数
			let diff = Math.abs(time1 - time2);

			// 将毫秒数转换为天数
			let days = Math.floor(diff / (1000 * 60 * 60 * 24));
			const interest = days / 365 * this.form.loanAmount * (this.form.interestRate / 100);
			this.form.interest = interest.toFixed(2);
			this.form.allMoney = this.form.interest * 1 + this.form.loanAmount * 1
		},
		saveLoanMain() {
			this.axios.post('/loanMain/saveLoanMain', (response) => {
				if (response.obj){
					ElMessage.success('借款成功!')
					const time = Date.parse(new Date());
					this.$router.push({
						name: 'Loan_index',
						params: {
							key: time,
							user_id: this.$route.params.user_id
						}
					});
				}
			}, this.form)
		}
	}
}
</script>

<style>

</style>